.flex {
    display: flex;
}

.flex-col {
  @extend .flex;
  flex-direction: column;
}

.flex-mid {
  @extend .flex;

  justify-items: center;
}

.flex-spacer {
  flex-grow: 1;
}
