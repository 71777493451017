// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
@import 'material-palettes';

// Custom typography rules
$my-custom-typography-config: mat-typography-config($font-family: 'Open Sans',
    $display-4: mat-typography-level('93px', $letter-spacing: -1.5px, $font-family: 'Comfortaa'),
    $display-3: mat-typography-level('58px', $letter-spacing: -0.5px, $font-family: 'Comfortaa'),
    $display-2: mat-typography-level('46px', $font-family: 'Comfortaa'),
    $display-1: mat-typography-level('33px', $letter-spacing: 0.25px, $font-family: 'Comfortaa'),
    $headline: mat-typography-level('23px', $font-family: 'Comfortaa'),
    $title: mat-typography-level('19px', $letter-spacing: 0.15px, $font-family: 'Comfortaa'),
    $subheading-2: mat-typography-level('15px', $letter-spacing: 0.15px, $font-family: 'Comfortaa'),
    $subheading-1: mat-typography-level('13px', $letter-spacing: 0.1px, $font-family: 'Comfortaa'),
  );

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($my-custom-typography-config);

// Use this to quickly switch between themes
$mat-primary: $mat-primary-green;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$griddle-primary: mat-palette($mat-primary);

// The warn palette is optional (defaults to red).
$griddle-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$griddle-theme: mat-dark-theme((color: (primary: $griddle-primary,
        accent: $griddle-primary,
        warn: $griddle-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($griddle-theme);

// Custom styling
.mat-dialog-container {
  background-color: #303030;
  padding: 0 !important;
  max-width: 38rem;
}

.mat-snack-bar-container {
  background-color: mat-color($griddle-primary, 100);

  &.error {
    background-color: mat-color($griddle-warn, 100);
  }

  .mat-simple-snackbar {
    justify-content: center;

    span {
      color: black;
    }

    .mat-simple-snackbar-action {
      margin: 0;

      button.mat-button {
        background-color: mat-color($griddle-primary, 500);

        span {
          color: white;
        }
      }
    }
  }
}