/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Helvetica Neue", sans-serif;
}

.hidden {
    display: none;
}

@import './assets/styles/material.scss';
@import './assets/styles/flex-helpers.scss';
@import './assets/styles/spacing-helpers.scss';